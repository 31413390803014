/***
* Tournament page styles
*/
.box-shadow-none {
    box-shadow: none;
}
.paper-hover {
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0,0,0,0.1);
}
.paper-hover:hover {
    border-color: rgba(0,17,255,0.45);
    box-shadow: 
        0px 2px 1px -1px rgba(0, 17, 255, 0.2),
        0px 1px 1px 0px rgba(0, 17, 255,0.14),
        0px 1px 3px 0px rgba(0, 17, 255,0.12);
}
