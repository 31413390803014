/***
* Match Score Styles
*/
.table {
    width: 100%;
}
.table thead tr th,
.table tbody tr td {
    padding: 6px;
    width: 17%;
}
.table thead tr th:first-child,
.table tbody tr td:first-child {
    width: 49%;
}
.table tbody tr td input[type="text"] {
    text-align: center;
}
.table tbody tr.row-other td {
    width: 65%;
}
.table tbody tr.row-other td:first-child {
    width: 35%;
}
.player-name p {
    text-overflow: clip;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 5px;
    max-width: 170px;
    min-width: auto;
}
.table input, .table p, .table div, .table span, .table select {
    font-size: 100%;
}