/***
* App Styles
*/
.inline-text {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inline-text.flex-start {
  justify-content: flex-start;
}
.inline-text.flex-end {
  justify-content: flex-end;
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 99999;
  width: 100%;
  height: 100%
}
.text-single-line {
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}