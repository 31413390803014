/***
* Match List page styles
*/
.inline-text {
    display: flex;
    justify-content: center;
    align-items: center;
}
.vertical-center {
    display: flex;
    align-items: center;
}
.line-vs {
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
}
.line-vs > p {
    text-align: center;
    position: relative;
    top: 10px;
    width: 30px;
    margin: 0 auto;
    background-color: #fff;
}